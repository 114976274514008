<template>
  <router-view/>
</template>

<script>
// import Example from './components/Example.vue'

export default {
  data() {
    return {
      componentName: 'App',
      count: 0
    }
  },
  // components: {
  //   Example
  // }
}
</script>

<style scoped>
</style>